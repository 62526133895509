// jQuery(document).ready(function ($) {

// });

document.addEventListener("DOMContentLoaded", () => {
  function initializeSwiper(selector, slidesPerView, overrideOptions = {}) {
    const defaultOptions = {
      centeredSlides: true,
      grabCursor: true,
      slidesPerView: slidesPerView,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: overrideOptions.spaceBetween || 40,
        },
        scrollbar: {
          dragSize: 0,
        },
      },
      loop: true,
      autoplay: {
        delay: 4000,
      },
      speed: 2000,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const swiperOptions = { ...defaultOptions, ...overrideOptions };
    return new Swiper(selector, swiperOptions);
  }
  function initializeBlogSwiper(selector, slidesPerView, overrideOptions = {}) {
    const defaultOptions = {
      centeredSlides: false,
      grabCursor: true,
      slidesPerView: slidesPerView,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: overrideOptions.spaceBetween || 0,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: overrideOptions.spaceBetween || 80,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: overrideOptions.spaceBetween || 40,
        },
      },
      loop: true,
      autoplay: {
        delay: 4000,
      },
      speed: 2000,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const swiperOptions = { ...defaultOptions, ...overrideOptions };
    return new Swiper(selector, swiperOptions);
  }

  const blogSwiperOptions = {
    centeredSlides: true,
    spaceBetween: 55,
  };
  const blogSwiper = initializeBlogSwiper(".blog-swiper", 3, blogSwiperOptions);

  // Inicijalizacija ostalih Swipera bez posebnih opcija
  const mySwiper = initializeSwiper(".mySwiper", 6);
  const restaurantSingleSwiper = initializeSwiper(
    ".restaurant-single-swiper",
    4
  );

  // Inicijalizacija Swipera sa dodatnim opcijama za rooms i apartments
  function initSpecificSwipers() {
    const roomsSwiperOptions = {
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      scrollbar: {
        el: "#scrollbar-rooms",
        dragSize: 430,
      },
      navigation: {
        nextEl: "#next-rooms",
        prevEl: "#prev-rooms",
      },
    };

    const apartmentsSwiperOptions = {
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          scrollbar: {
            dragSize: 200,
          },
        },

        768: {
          slidesPerView: 1,
          spaceBetween: 40,
          scrollbar: {
            dragSize: 430,
          },
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      navigation: {
        nextEl: "#next-apartments",
        prevEl: "#prev-apartments",
      },
    };

    const singleRoomSwiperOptions = {
      slidesPerView: 1,
      loop: true,
      effect: "coverflow",
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 250,
        modifier: 1,
        slideShadows: true,
      },
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
          scrollbar: {
            dragSize: 2,
          },
        },
        780: {
          slidesPerView: 2,
          spaceBetween: 0,
          scrollbar: {
            dragSize: 430,
          },
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
      scrollbar: {
        el: "#swiper-scrollbar-single",
        dragSize: 430,
      },
      navigation: {
        nextEl: "#swiper-button-next-single",
        prevEl: "#swiper-button-prev-single",
      },
      on: {
        slideChange: function () {
          var slides = document.querySelectorAll(
            "#roomSwiperSingle .swiper-slide"
          );
          slides.forEach(function (slide) {
            slide.classList.remove("swiper-slide-active");
          });
          slides[this.activeIndex].classList.add("swiper-slide-active");
        },
      },
    };

    new Swiper("#rooms-swiper", roomsSwiperOptions);
    new Swiper("#apartments-swiper", apartmentsSwiperOptions);
    new Swiper("#roomSwiperSingle", singleRoomSwiperOptions);
  }

  // Pozivanje funkcije za inicijalizaciju specifičnih Swipera
  initSpecificSwipers();

  function inViewport(element, additionalHeight = 0) {
    const rect = element.getBoundingClientRect();
    return rect.top + additionalHeight <= window.innerHeight;
  }

  function checkScroll() {
    var header = document.querySelector("header");
    var scrollPosition = window.scrollY || document.documentElement.scrollTop;

    if (scrollPosition > window.innerHeight / 3) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  }

  window.addEventListener("scroll", checkScroll);

  checkScroll();

  const menuItemsWithChildren = document.querySelectorAll(
    ".menu-item-has-children"
  );

  function showSubMenu(event) {
    const itemWithChildren = event.currentTarget;
    clearTimeout(itemWithChildren.dataset.submenuTimeout);

    const submenu = itemWithChildren.querySelector(".sub-menu");
    if (submenu) {
      submenu.classList.add("visible");
    }
  }

  function hideSubMenu(event) {
    const itemWithChildren = event.currentTarget;

    // Set a timeout to hide the submenu after 1000 milliseconds (1 second)
    itemWithChildren.dataset.submenuTimeout = setTimeout(function () {
      const submenu = itemWithChildren.querySelector(".sub-menu");
      if (submenu) {
        submenu.classList.remove("visible");
      }
    }, 250);
  }

  menuItemsWithChildren.forEach(function (itemWithChildren) {
    itemWithChildren.addEventListener("mouseenter", showSubMenu);
    itemWithChildren.addEventListener("mouseleave", hideSubMenu);
  });

  const ctaButtons = document.querySelectorAll(".cta");
  const otherLineAnimationElements = document.querySelectorAll(".animate-line");

  const lineAnimationElements = [...ctaButtons, ...otherLineAnimationElements];

  const animateCta = () => {
    lineAnimationElements.forEach((button) => {
      if (inViewport(button, 100)) {
        button.classList.add("animated");
      }
    });
  };

  document.addEventListener("scroll", animateCta);

  animateCta();

  const opacityAnimatedItems = document.querySelectorAll(
    ".animate-scroll-opacity"
  );

  const animateScrollOpacity = () => {
    opacityAnimatedItems.forEach((element) => {
      if (inViewport(element, 120)) {
        element.classList.add("animated");
      }
    });
  };

  document.addEventListener("scroll", animateScrollOpacity);

  animateScrollOpacity();

  const tabs = document.querySelectorAll(".tab");

  tabs.forEach((tab) => {
    const tabName = tab.dataset.name;

    tab.addEventListener("click", () => {
      showTab(tabName);

      if (roomsSwiper) {
        roomsSwiper.destroy();
      }
      if (apartmentsSwiper) {
        apartmentsSwiper.destroy();
      }

      showTab(tabName);

      // Recreate Swiper instances after changing tabs
      initSwipers();
    });
  });

  function showTab(tabName) {
    const tabContents = document.querySelectorAll(".tab-content");
    tabContents.forEach((content) => {
      content.classList.remove("active");
    });

    tabs.forEach((tab) => {
      tab.classList.remove("active");
    });

    // Show the selected tab content
    const selectedContent = document.getElementById(`${tabName}-content`);
    if (selectedContent) {
      selectedContent.classList.add("active");
    }

    const selectedTab = document.getElementById(`${tabName}-tab`);
    if (selectedTab) {
      selectedTab.classList.add("active");
    }
  }

  const bookNowButton = document.querySelector(".menu-item-1002");

  function isHomepage() {
    return (
      window.location.pathname === "/" ||
      window.location.pathname === "/leopold/"
    );
  }

  function showButtonOnScroll() {
    const windowHeight = window.innerHeight * 0.8;

    window.addEventListener("scroll", function () {
      // Check if the scroll position is greater than or equal to one window height
      if (window.scrollY >= windowHeight) {
        bookNowButton.classList.add("visible");
      } else {
        bookNowButton.classList.remove("visible");
      }
    });
  }

  if (!isHomepage()) {
    bookNowButton?.classList.add("visible");
  } else {
    showButtonOnScroll();

    window.addEventListener("load", function () {
      // Check if the scroll position is already greater than or equal to 80% of the window height
      if (window.scrollY >= window.innerHeight * 0.8) {
        bookNowButton.classList.add("visible");
      }
    });
  }
});

//! HAMBURGER MENI
document.addEventListener("DOMContentLoaded", function () {
  const hamburger = document.getElementById("hamburger");
  const mobileMenuParent = document.getElementById("mobile-menu-parent");
  const menuItemsWithChildren = document.querySelectorAll(
    ".menu-item-has-children"
  );

  menuItemsWithChildren.forEach(function (menuItem) {
    const subMenu = menuItem.querySelector(".sub-menu");
    if (subMenu) {
      menuItem.addEventListener("click", function (event) {
        event.stopPropagation();
        const isOpen = subMenu.style.display === "block";
        if (isOpen) {
          subMenu.style.display = "none";
        } else {
          subMenu.style.display = "block";
        }
      });
    }
  });

  hamburger.addEventListener("click", function (event) {
    event.stopPropagation();
    this.classList.toggle("active");
    mobileMenuParent.classList.toggle("active");

    if (mobileMenuParent.classList.contains("active")) {
      mobileMenuParent.style.marginLeft = "0";
    } else {
      mobileMenuParent.style.marginLeft = "-100%";
    }
  });

  document.addEventListener("click", function (event) {
    if (!event.target.closest("#mobile-menu-parent")) {
      hamburger.classList.remove("active");
      mobileMenuParent.classList.remove("active");
      mobileMenuParent.style.marginLeft = "-100%";

      menuItemsWithChildren.forEach(function (menuItem) {
        const subMenu = menuItem.querySelector(".sub-menu");
        if (subMenu) {
          subMenu.style.display = "none"; // Postavljamo display na none kada se zatvori
        }
      });
    }
  });
});

//! IOS SLIDER
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (isIOS) {
  const img = document.querySelector(".hero .bg-img");

  if (img) {
    img.style.backgroundAttachment = "scroll";
  }
}

//! BACK TO TOP BUTTON

document.addEventListener("DOMContentLoaded", function () {
  var backToTopButton = document.querySelector(".back-to-top-button");

  function toggleBackToTopButton() {
    if (window.scrollY > 300) {
      backToTopButton.style.display = "block";
    } else {
      backToTopButton.style.display = "none";
    }
  }

  window.addEventListener("scroll", toggleBackToTopButton);

  window.addEventListener("resize", toggleBackToTopButton);

  toggleBackToTopButton();

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  backToTopButton.addEventListener("click", scrollToTop);
});
